import { IHopDongResponseBase } from '../interfaces/ApiResponses';

export const useBienBan = () => {
  const calculateSoLuongVatTuCan = ({
    bien_ban_kiem_nghiem: bienBanItems,
    hop_dong_vat_tu: hopDongVatTuItems
  }: IHopDongResponseBase) => {
    const result: number[] = [];
    hopDongVatTuItems.forEach(({
      id: idVatTuHopDong,
      pivot: {
        so_luong: soLuongTongCong,
      },
    }) => {
      let soLuongDaCo = 0;

      bienBanItems.forEach((bienBanItem) => {
        const vatTuBienBanItem = bienBanItem.vat_tu_bien_ban_kiem_nghiem.find(vatTuBienBan => vatTuBienBan.id === idVatTuHopDong);

        if (vatTuBienBanItem) {
          const soLuongTrongBienBan = +vatTuBienBanItem.pivot.so_luong || 0;

          soLuongDaCo += soLuongTrongBienBan;
        }
      });

      if (!isNaN(+soLuongTongCong - +soLuongDaCo)) {
        result.push(+soLuongTongCong - +soLuongDaCo)
      }
    });

    return result;
  }

  return {
    calculateSoLuongVatTuCan
  }
}
