
import { defineComponent, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { useAccount } from '@/core/hooks/use-account';
import { IHopDongResponseBase, IKhoResponseBase, IVatTuBienBan } from '@/core/interfaces/ApiResponses';
import { useLoading } from '@/core/hooks/use-loading';
import { HopDongService } from '@/core/services/HopDong.service';
import {
  getErrorMsg,
  getHoiDongBbkn,
  setHoiDongBbkn,
  swalNotification,
} from '@/core/helpers/utils';
import { DonViService } from '@/core/services/DonVi.service';
import { KhoService } from '@/core/services/Kho.service';
import { useDateTime } from '@/core/hooks/use-date-time-hook';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { useCreatingForm } from '@/core/hooks/use-creating-form';
import { BienBanKiemNghiemService } from '@/core/services/BienBanKiemNghiem.service';
import { useBienBan } from '@/core/hooks/use-bien-ban-hook';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';
import { PhieuNhapKhoService } from '@/core/services/PhieuNhapKho.service';

import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';
import ButtonCustom from '../../../../components/shared/buttons/ButtonCustom.vue';
import VatTuBienBanTable from './vat-tu-bien-ban-kiem-nghiem/VatTuBienBanTable.vue';
import ToolService from '../../../../core/services/Tool.service';
import LabelCustom from '@/components/shared/label-custom/LabelCustom.vue';

export default defineComponent({
  name: 'bien-ban-kiem-nghiem-form',

  components: {LabelCustom, PageRowLayout, ButtonCustom, VatTuBienBanTable },

  data() {
    return {
      changed: false,
      khoItems: [] as IKhoResponseBase[],
      availableKhoItems: [{
        label: 'Chưa chọn',
        value: 0,
      }] as { label: string, value: number}[],
      vatTuBienBanItems: [] as IVatTuBienBan[],
      soLuongCanArr: [] as number[],
      soPhieuNhap: null as string |  null,
      totalPriceInText: 'Không',
    }
  },

  setup() {
    const { currentUserData  } = useAccount();
    const { formRef } = useCreatingForm();
    const { goBack, replace } = useRouterCustom();
    const { replace: replaceRouter } = useRouter();
    const { startLoading, endLoading, isLoading } = useLoading();
    const { moment } = useDateTime();
    const { calculateSoLuongVatTuCan } = useBienBan();
    const { ButtonsType, ButtonTypeColors } = useButtonCustom();
    const { isCreating, id: idPhieuNhapKho } = useCreatingForm('idPhieuNhap')
    const idHopDong = ref(0);
    const tenDonVi = ref('');
    const hopDongDetail = ref<IHopDongResponseBase | null>(null);

    const form = reactive({
      ngayKiemNghiem: new Date(),
      idKho: 0,
      tenGiamDoc: '',
      yKien: '',
    });

    const hoiDongKiemNghiemItems = [] as {
      thuTu: number,
      hoTen: string,
      chucVu: string,
      thanhPhan: string,
    }[];

    for (let i = 0; i <= 3; i++) {
      hoiDongKiemNghiemItems.push({
        thuTu: i + 1,
        hoTen: '',
        chucVu: '',
        thanhPhan: '',
      });
    }

    const hoiDongKiemNghiem = reactive(getHoiDongBbkn().length ? getHoiDongBbkn() : hoiDongKiemNghiemItems);

    const rules = reactive({
      ngayKiemNghiem: [
        {
          required: true,
          message: "Phải nhập ngày",
          trigger: "blur",
        }
      ],
      yKien: [
        {
          required: true,
          message: 'Ý kiến không được bỏ trống',
          trigger: 'blur',
        }
      ]
    });

    return {
      ButtonsType, ButtonTypeColors,
      goBack, replace, replaceRouter,
      idDonVi: currentUserData.value.departmentId,
      isCreating, idPhieuNhapKho,
      idHopDong,
      tenDonVi,
      hopDongDetail,
      startLoading, endLoading, isLoading,
      moment,
      form, hoiDongKiemNghiem, rules, formRef,
      calculateSoLuongVatTuCan,
    }
  },

  async mounted() {
    this.idHopDong = +this.$route.params.idHopDong;

    setCurrentPageTitle('Biên bản kiểm nghiệm');

    await this.fetchThongTinHopDong();

    await this.fetchKhoItems();

    if (!this.isCreating) {
      await this.fetchPhieuNhapDetail();
    }
  },

  methods: {
    formatCurrencyAndQuantity,

    onInputChange({ items }: { items: IVatTuBienBan[] }) {
      this.changed = true;

      this.vatTuBienBanItems = [...items];
    },

    handleDonGiaUpdate({ index, value }: { index: number, value: number }) {
      this.vatTuBienBanItems[index].pivot.so_tien = value;
    },

    onInputPhuongThuc({index, value}) {
      this.vatTuBienBanItems[index].phuong_thuc = value;
    },

    async fetchThongTinHopDong() {
      try {
        await this.startLoading();

        const { data: { data: res } } = await HopDongService.fetchContractDetail(this.idHopDong);

        const { data: { data: { ten_don_vi }}} = await DonViService.fetchCurrentDepartmentById(this.idDonVi);

        this.tenDonVi = ten_don_vi;

        this.hopDongDetail = res;

        if (this.hopDongDetail && this.hopDongDetail.hop_dong_vat_tu) {
          this.soLuongCanArr = this.calculateSoLuongVatTuCan(this.hopDongDetail);
        }

        if (this.hopDongDetail && res.hop_dong_vat_tu) {
          this.vatTuBienBanItems = res.hop_dong_vat_tu.map((item, index) => ({
            ...item,
            phuong_thuc: '',
            so_luong_trong_bien_ban: 0,
            so_luong_can: this.soLuongCanArr[index],
            so_luong_nhap_toi_da: item.pivot.so_luong_chua_nhap_kho,
            is_dat: true,
          }));
        }
        await this.endLoading();
      } catch (err) {
        await this.endLoading();

        await swalNotification(
					getErrorMsg(err, 'Có lỗi xảy ra khi lấy thông tin hợp đồng'),
					'error',
				);
      }
    },

    async fetchKhoItems() {
      try {
        await this.startLoading();

        const { data: { data: { data: response } } } = await KhoService.fetchAllWarehousesByDepartmentId(this.idDonVi);

        this.khoItems = [...response];

        this.availableKhoItems = [
          ...this.availableKhoItems,
          ...this.khoItems.map(item => ({
            value: item.id,
            label: item.ten_kho,
          }))
        ];

        await this.endLoading();
      } catch (err) {
        await this.endLoading();

        await swalNotification(
					getErrorMsg(err, 'Có lỗi xảy ra khi lấy thông tin hợp đồng'),
					'error',
				);
      }
    },

    onAddNguoiKiemNghiem() {
      const newThuTu = this.hoiDongKiemNghiem.length + 1;
      this.hoiDongKiemNghiem.push({
        thuTu: newThuTu,
        hoTen: '',
        chucVu: '',
        thanhPhan: '',
      })
    },

    onDeleteNguoiKiemNghiem() {
      this.hoiDongKiemNghiem.pop();
    },

    async fetchPhieuNhapDetail() {
      try {
        const {
          data: {
            data: {
              id_kho: fetchIdKho,
              bien_ban_kiem_nghiem: bienBanKiemNghiemData,
              so_phieu,
              vat_tu_nhap_kho: vatTuDaNhapKhoItems,
            }
          }
        } = await PhieuNhapKhoService.get(this.idPhieuNhapKho);

        this.form.idKho = fetchIdKho;

        this.soPhieuNhap = so_phieu;

        this.vatTuBienBanItems.forEach((vatTuItem, index) => {
          const foundVatTu = vatTuDaNhapKhoItems.find(vatTuDaNhapItem => vatTuDaNhapItem.id === vatTuItem.id);

          if (foundVatTu) {
            this.vatTuBienBanItems[index].so_luong_trong_bien_ban = foundVatTu.pivot.so_luong;
          } else {
            this.vatTuBienBanItems[index].so_luong_trong_bien_ban = 0;
          }
        });

        if (bienBanKiemNghiemData) {
          this.form.yKien = bienBanKiemNghiemData.y_kien;

          this.form.tenGiamDoc = bienBanKiemNghiemData.ten_giam_doc;

          this.form.ngayKiemNghiem = new Date(bienBanKiemNghiemData.ngay_bien_ban);

          this.hoiDongKiemNghiem = bienBanKiemNghiemData?.thanh_phan_hoi_dong
            ? JSON.parse(bienBanKiemNghiemData.thanh_phan_hoi_dong)
            : [];
        }
      } catch (e) {
        await this.endLoading();

        await swalNotification(
          getErrorMsg(e, 'Có lỗi khi lấy thông tin phiếu nhập hoặc biên bản'),
          'error',
        );
      }
    },

    payloadTransform() {
      if (!this.isCreating) {
        return {
          id: this.idPhieuNhapKho,
          vat_tu_items: this.vatTuBienBanItems.map(({ id, so_luong_trong_bien_ban, pivot: { so_tien }, phuong_thuc }) => ({
            id,
            so_luong: so_luong_trong_bien_ban,
            don_gia: so_tien,
            ...(this.hopDongDetail?.loai_hop_dong === 2 && {
              don_gia: so_tien,
            }),
            ...(phuong_thuc && {
              phuong_thuc_kiem_nghiem: phuong_thuc,
            }),
          })),
        }
      }

      setHoiDongBbkn(JSON.stringify(this.hoiDongKiemNghiem));

      return {
        ngay_bien_ban: this.moment(this.form.ngayKiemNghiem.toString()).format('YYYY-MM-DD'),
        thanh_phan_hoi_dong: this.hoiDongKiemNghiem,
        y_kien: this.form.yKien,
        ten_giam_doc: this.form.tenGiamDoc,
        id_kho: this.form.idKho,
        id_hop_dong: this.idHopDong,
        loai_hop_dong: this.hopDongDetail?.loai_hop_dong ? this.hopDongDetail?.loai_hop_dong : 1,
        vat_tu_items: this.vatTuBienBanItems.map(item => ({
          id: item.id,
          so_luong: item.so_luong_trong_bien_ban,
          ket_qua_kiem_nghiem: item.is_dat ? 1 : 0,
          phuong_thuc_kiem_nghiem: item.phuong_thuc,
          ...(this.hopDongDetail?.loai_hop_dong === 2 && {
            don_gia: item.pivot.so_tien,
          }),
        })),
      }
    },

    onSubmit() {
      if (!this.formRef) {
				return;
			}

      if (!this.form.idKho) {
        swalNotification(
          'Bắt buộc phải chọn kho',
          'error',
        );

        return;
      }

      this.formRef.validate(async (valid) => {
        if (valid) {
          try {
            await this.startLoading();

            // Tạm thời chỉ update vật tư nên sẽ submit update vật tư items và redirect sang trang detail. Về sau có thể support sửa car những thông tin khác
            if (!this.isCreating) {
              await PhieuNhapKhoService.updateVatTuItemsPhieuNhapLe(this.payloadTransform());

              await this.endLoading();

              await swalNotification(
                'Vật tư phiếu nhập kho cập nhật thành công',
              );

              await this.replaceRouter({
                name: 'chi-tiet-phieu-nhap-kho',
                params: {
                  id: this.idPhieuNhapKho,
                },
              });

              return;
            }

            const {
              data: {
                data: {
                  phieu_nhap_kho: {
                    id: newIdPhieuNhap,
                  },
                  id: idBienBan,
                },
              },
            } = await BienBanKiemNghiemService.createFromHopDong(this.payloadTransform());

            await this.endLoading();

            await this.replace(`/quan-ly/hop-dong/${this.idHopDong}/bien-ban/${idBienBan}/phieu-nhap-kho/${newIdPhieuNhap}/chinh-sua`);

            return;
          } catch (e) {
            await this.endLoading();

            await swalNotification(
              getErrorMsg(e, 'Có lỗi, không tạo được biên bản'),
              'error',
            );
          }
          return;
        }

        return false;
      });
    }
  },

  computed: {
    khoOptions(): { value: number, label: string }[] {
      const result = this.khoItems.map(item => ({
        value: item.id,
        label: item.ten_kho,
      }));

      result.push({
        value: 0,
        label: 'Chưa chọn',
      });

      return result;
    },
    totalPrice() {
      return Math.round(this.vatTuBienBanItems.reduce((acc, vatTuItem) => +vatTuItem.so_luong_trong_bien_ban * +vatTuItem.pivot.so_tien + acc, 0))
    },
    totalPriceInNumber() {
      return formatCurrencyAndQuantity(this.totalPrice, false);
    },
    allowEditingDonGia() {
      return this.hopDongDetail?.loai_hop_dong === 2;
    },
  },

  watch: {
    totalPrice: {
      async handler(value) {
        if (!value) {
          this.totalPriceInText = 'Không';
        } else {
          const {
            data: { data },
          } = await ToolService.convertPriceToText(value);

          this.totalPriceInText = data;
        }
      },
      immediate: true,
    },
  },
});
